import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  APIS,
  COOKIES,
  QUERY_STRINGS,
  RINGBA_STORAGE_KEYS,
  STORAGE_KEYS,
  sessionStorageKeys,
  replaceShortCodes as shortCodeReplacer,
  useEventID,
  useInitRingba,
  useRingba,
  useVisitorId,
} from "wecall-config-lib";
import psl from "psl";

export default function QuizInitialScripts({ setNumber }) {
  const [clickId, setClickId] = useState();
  const fbc = Cookies.get("_fbc" || "");
  const fbp = Cookies.get("_fbp" || "");

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  const domainName = window.domain_settings.parentDomain || "";

  const visitorId = useVisitorId();
  const { getEventId } = useEventID();
  const eventID = getEventId();

//   const testRingba = window.domain_settings.rinbaUser;

//   let ringba = testRingba;
//   const generator = ringba.user;
//   const { number } = useInitRingba({
//     ringbaKey: {
//       key: testRingba.key,
//       number: testRingba.number,
//       user: testRingba.user,
//     },
//   });

  const [stateCityResponse, setStateCityResponse] = useState({
    state: "",
    city: "",
    zip: "",
  });


  const setInitialData = () => {
    window._rgba_tags = window._rgba_tags || []

    window._rgba_tags.push(
      { [RINGBA_STORAGE_KEYS.visitor_id]:
      localStorage.getItem(STORAGE_KEYS.localStorageKeys.visitorId)}
    );

    QUERY_STRINGS.forEach((i) => {
      if (
        params.get(i.redirectString) &&
        params.get(i.redirectString).length > 0
      ) {
        window._rgba_tags.push({ [i.ringbaKey]: params.get(i.redirectString) });
      }
    });

    window._rgba_tags.push({ vl_click_id: params.get("vl_click_id") });

    window._rgba_tags.push({[
      RINGBA_STORAGE_KEYS.fbPixelId]:
      window.domain_settings.facebookPixel}
    );


    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.domainName]: domainName });
    // window._rgba_tags.push({ ["generator"]:  generator });

    COOKIES.forEach((i) => {
      Cookies.set(i.key, params.get(i.key));
      Cookies.set(i.key, params.get(i.key), {
        domain: domainName,
      });
    });

    Cookies.set("visitor_id", visitorId);
    Cookies.set("visitor_id", visitorId, {
      domain: domainName,
    });

    localStorage.setItem(sessionStorageKeys.wbraid, params.get("wbraid"));
    localStorage.setItem(sessionStorageKeys.gclid, params.get("gclid"));
    localStorage.setItem(sessionStorageKeys.grbaid, params.get("grbaid"));

    getIpAdd();

    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.event_id]:  eventID});
  };

  useEffect(() => {
    if (fbc) {
      window._rgba_tags.push({ [(RINGBA_STORAGE_KEYS.fbc)]: fbc });
      localStorage.setItem(sessionStorageKeys.fbc, fbc);
    }
    if (fbp) {
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.fbp]: fbp});
      localStorage.setItem(sessionStorageKeys.fbp, fbp);
    }
  }, [fbc, fbp]);

//   useEffect(()=>{
//     setNumber(number);
//   },[number])


  const getIpAdd = async () => {
    let userIp;
    try {
      var response;
      response = await axios.get(APIS.GET_IP_ADDRESS, {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
      });
      userIp = response.data["ip"];
      localStorage.setItem(sessionStorageKeys.userIp, userIp);
    } catch (error) {
      console.error("IpError" + error);
    }
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.userIp]: userIp });
  };

  useEffect(() => {
    if (eventID && eventID.length) {
      Cookies.set(RINGBA_STORAGE_KEYS.event_id, eventID);
      Cookies.set(RINGBA_STORAGE_KEYS.event_id, eventID, {
        domain: domainName,
      });
      localStorage.setItem("eventID", eventID);
    }
  }, [eventID]);

  useEffect(() => {
    setInitialData();
  }, []);

  useEffect(() => {
    if (clickId) {
      window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.vl_click_id]: clickId });
      Cookies.set(RINGBA_STORAGE_KEYS.vl_click_id, clickId, {
        domain: domainName,
      });
      sessionStorage.setItem(
        STORAGE_KEYS.sessionStorageKeys.isClickIdStored,
        "true"
      );
    }
  }, [clickId]);

  useEffect(() => {
    if (visitorId) {
      Cookies.set("visitor_id", visitorId);
      Cookies.set("visitor_id", visitorId, {
        domain: domainName,
      });
    }
  }, [visitorId]);

  useEffect(() => {
    const scriptId = "volumScript";
    const volumScript = window.document.getElementById(scriptId);
    if (volumScript) {
    } else {
      const baseUrl = "https://lander-main-microservice.netlify.app/";
      const src = baseUrl + "volumOfferScript.js";
      const doc = document.createElement("script");
      doc.src = src;
      doc.id = scriptId;
      doc.async = false;
      window.document.body.appendChild(doc);
    }
  }, []);

  useEffect(() => {
    if (eventID && eventID.length) {
      Cookies.set(RINGBA_STORAGE_KEYS.event_id, eventID);
      Cookies.set(RINGBA_STORAGE_KEYS.event_id, eventID, {
        domain: domainName,
      });
      localStorage.setItem(sessionStorageKeys.eventID, eventID);
    }
  }, [eventID]);

  return (
    <>
      {!clickId ? (
        <GetClickId
          clickId={clickId}
          showQuizSection={true}
          setClickId={setClickId}
        />
      ) : undefined}
    </>
  );
}

function GetClickId(props) {
  // React.useEffect(() => {
  //   if (!props.clickId) {
  //       const interval = setInterval(() => {
  //           if (props.showQuizSection) {
  //               window.dtpCallback &&
  //                   window.dtpCallback(() => {
  //                       const clickId = dtpCallback.getClickID();
  //                       props.setClickId(clickId);
  //                       sessionStorage.setItem("clickId", clickId);
  //                   });
  //           } else {
  //               window.dtpCallback &&
  //                   window.dtpCallback(() => {
  //                       const clickId = window.dtpCallback.params.click_id;
  //                       props.setClickId(clickId);
  //                       sessionStorage.setItem("clickId", clickId);
  //                       localStorage.setItem("vl_click_id", clickId);
  //                   });
  //           }
  //       }, 400);
  //       return () => clearInterval(interval);
  //   }
  // }, []);
  return <></>;
}
